@media(min-width:1280px) {

    .inner-sub-heading {
        font-size: 56px;
        line-height: 62px;
        max-width: 640px;
        letter-spacing: -4px;
    }

    .inner-sub-heading.wider {
        max-width: 850px!important;
    }

    .portfolio-pr-el .container-el .el .illu {
        width: 45%;
    }
    
    .portfolio-pr-el .container-el .el {
        margin-bottom: 150px;
    }
    
    .portfolio-pr-el .container-el .el .wrapper .container-img {
        width: 480px;
        height: 452px;
    }
    
    .portfolio-pr-el .container-el .el:nth-child(odd) .wrapper .container-text {
        margin-left: 150px;
    }
    
    .portfolio-pr-el .container-el .el:nth-child(even) .wrapper .container-text {
        margin-right: 150px;
    }
    
    .is-portfolio-page .android {
        right: 0;
    }
    .is-portfolio-page .lamp {
        left: -15px;
        top: 260px;
    }
    .is-portfolio-page .playstore {
        left: 20px;
        top: 100px;
    }
    
    .banner__background {
        min-height: 1220px;
    }
    
    .fly-menu .fly-menu-inner .menu-wrapper .container-nav li a:not(.social) .word-wrap .word-wrap-inner {
        line-height: 78px;
    }
    
    .background-shapes__circle-filled {
        height: 575px;
        width: 575px;
    }
    
    .fly-menu .fly-menu-inner .menu-wrapper {
        width: clamp(1200px,80vw,2200px);
    }
}

@media(min-width:1366px) {
    .inner-sub-heading {
        font-size: 64px;
        line-height: 70px;
        max-width: 700px;
    }

    .inner-sub-heading.wider {
        max-width: 1000px!important;
    }

    .inner-sub-heading.xl {
        max-width: 750px;
    }
}

@media(min-width:1600px) {

    .inner-hero-heading {
        font-size: 115px;
        line-height: 125px;
        max-width: 1000px;
    }

    .menu-r-section .header-menu .header-link {
        font-size: 16px;
        letter-spacing: -0.75px;
    }

    .menu-r-section .header-menu .menu-item {
        margin-right: 15px;
    }
}

@media(min-width:1024px) and (max-width:1319px) {
    .container {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media(min-width:1200px) and (max-width:1366px) {
    .container {
        max-width: 1200px;
    }
}

@media(min-width:1367px) and (max-width:1479px) {
    .container {
        max-width: 1300px;
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media(min-width:1480px) and (max-width:1600px) {
    .container {
        max-width: 1440px;
    }
}
